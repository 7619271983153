import { setInteractionMode } from 'vee-validate';
import { extend } from 'vee-validate';
import { alpha, email, max, regex, required } from 'vee-validate/dist/rules';
import { configure } from 'vee-validate';
import { localize } from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl.json';

export function initVeeValidate() {
    const rules = {
        alpha,
        email,
        max,
        regex,
        required
    };

    Object.keys(rules).forEach(rule => {
        extend(rule, rules[rule]);
    });

    setInteractionMode('eager');
    localize('nl', nl);

    localize({
        nl: {
            fields: {
                PrivacyReferer: {
                    required: 'Akkoord met de privacy voorwaarden is vereist.'
                },
                PrivacyAthlete: {
                    required: 'Akkoord met de privacy voorwaarden is vereist.'
                }
            }
        }
    });

    configure({
        classes: {
            valid: 'is-valid',
            invalid: 'is-invalid',
            dirty: 'is-dirty'
        }
    });
}
