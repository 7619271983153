<template>
    <div class="activity-cta">
        <div class="activity-cta__image mb-3">
            <img :src="image" :alt="altText" />
        </div>

        <button class="btn btn-primary" @click="emitAthleteEvent">
            Meld jezelf aan voor een les
        </button>
        <button class="btn btn-secondary" @click="emitRefererEvent">
            Meld je cliënt aan voor een les
        </button>
        <a
            v-if="registerMeeLink"
            class="btn btn-primary d-block text-center activity-cta__register-link"
            :href="registerMeeLink.url"
            :target="registerMeeLink.target"
            >{{ registerMeeLink.name }}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: ''
        },
        altText: {
            type: String,
            default: ''
        },
        registerMeeLink: {
            type: Object,
            default: null
        }
    },
    methods: {
        emitAthleteEvent() {
            this.$root.$emit('open-form', { openRefererForm: false });
        },

        emitRefererEvent() {
            this.$root.$emit('open-form', { openRefererForm: true });
        }
    }
};
</script>

<style lang="scss">
.activity-cta__image {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 3px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;

    img {
        width: auto;
        height: 100%;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 50px;
        bottom: -14px;
        left: -20px;
        right: -20px;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0) 0%,
            #ffffff 50%
        );
    }

    @include media-breakpoint-up(lg) {
        margin-top: -40%;
    }
}

.activity-cta__register-link {
    &:hover {
        color: #ffffff;
    }
}
</style>
