import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const SentryConfig = {
    init(environment, sentryDSN) {
        if (
            environment.toLowerCase() !== 'development' &&
            environment.toLowerCase() !== 'local'
        ) {
            Sentry.init({
                dsn: sentryDSN,
                integrations: [
                    new Integrations.Vue({ Vue, attachProps: true })
                ],
                environment: environment
            });
        }
    }
};

export default SentryConfig;
