<template>
    <fieldset class="checkbox-group">
        <slot></slot>
        <ul class="checkbox-group__list">
            <li v-for="option in options" :key="option.id" class="checkbox">
                <template v-if="option.remainingActivities > 0">
                    <input
                        :id="option.filter"
                        v-model="selectedFilters"
                        class="checkbox__input"
                        type="checkbox"
                        :value="option.filter"
                    />
                    <label :for="option.filter" class="checkbox__label">
                        <span>
                            {{ option.name }} [{{ option.remainingActivities }}]
                        </span>
                    </label>
                </template>
            </li>
        </ul>
    </fieldset>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            default() {
                return [];
            }
        },

        value: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            selectedFilters: []
        };
    },

    watch: {
        selectedFilters(val) {
            this.$emit('input', val);
        }
    },

    created() {
        this.selectedFilters = this.value;
    },

    updated() {
        this.selectedFilters = this.value;
    }
};
</script>

<style lang="scss">
.checkbox-group__list {
    padding-left: 1rem;
}

.checkbox {
    list-style: none;
    &:hover {
        .checkbox__label span {
            margin-left: 5px;
        }
    }
}

.checkbox__input {
    display: none;
}

.checkbox__label {
    position: relative;
    display: block;
    user-select: none;
    padding-left: 22px;
    span {
        transition: margin 0.3s;
    }
    &:hover {
        cursor: pointer;
    }
}

.checkbox__label:before,
.checkbox__label:after {
    position: absolute;
    display: block;
}

.checkbox__label:before {
    content: '';
    width: 16px;
    height: 16px;
    left: 0;
    top: 8px;
    border: 1px solid $black;
    border-radius: 2px;
}

.checkbox__label:after {
    height: 7px;
    width: 11px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 2px;
    top: 11px;

    .checkbox__input:checked + & {
        content: '';
    }
}
</style>
