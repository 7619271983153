var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.formIsOpen),expression:"formIsOpen"}],ref:"form",staticClass:"registration-form"},[_c('div',{staticClass:"bg--blue-dark py-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-lg-6"},[_vm._t("intro")],2),_c('div',{staticClass:"col-1"},[_c('button',{staticClass:"registration-form__button",on:{"click":_vm.closeForm}},[_c('i',{staticClass:"fas fa-lg fa-times"})])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showErrorsOrSuccess),expression:"!showErrorsOrSuccess"}],staticClass:"bg--blue py-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row "},[(_vm.showRefererForm)?_c('validation-observer',{ref:"refererForm",staticClass:"col-12 col-md-6",attrs:{"tag":"div"}},[_c('form',{staticClass:"row mb-3 mb-lg-0",attrs:{"id":"referer-form"}},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"mb-2 mb-lg-4"},[_vm._v("Gegevens Verwijzer")])]),_c('text-input',{staticClass:"col-6",attrs:{"name":"Voornaam","rules":"required"},model:{value:(_vm.referer.firstName),callback:function ($$v) {_vm.$set(_vm.referer, "firstName", $$v)},expression:"referer.firstName"}}),_c('text-input',{staticClass:"col-6",attrs:{"name":"Achternaam","rules":"required"},model:{value:(_vm.referer.lastName),callback:function ($$v) {_vm.$set(_vm.referer, "lastName", $$v)},expression:"referer.lastName"}}),_c('text-input',{staticClass:"col-6",attrs:{"name":"E-mailadres","rules":"required|email"},model:{value:(_vm.referer.email),callback:function ($$v) {_vm.$set(_vm.referer, "email", $$v)},expression:"referer.email"}}),_c('text-input',{staticClass:"col-6",attrs:{"name":"Telefoonnummer","rules":{
                                max: 11,
                                regex: /\d{3,4}[ -]{0,1}\d{6,7}/
                            }},model:{value:(_vm.referer.telephoneNumber),callback:function ($$v) {_vm.$set(_vm.referer, "telephoneNumber", $$v)},expression:"referer.telephoneNumber"}}),_c('dropdown-input',{staticClass:"col-6",attrs:{"options":_vm.refererTypes,"name":"Type verwijzer","rules":"required"},model:{value:(_vm.referer.referrerType),callback:function ($$v) {_vm.$set(_vm.referer, "referrerType", $$v)},expression:"referer.referrerType"}}),_c('text-input',{staticClass:"col-6",attrs:{"name":"Praktijk/Locatie","rules":"required"},model:{value:(_vm.referer.practice),callback:function ($$v) {_vm.$set(_vm.referer, "practice", $$v)},expression:"referer.practice"}}),_c('text-input',{staticClass:"col-12 col-xl-6",attrs:{"name":"Postcode (1234AB)","rules":{
                                required: true,
                                max: 11,
                                regex: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/
                            }},model:{value:(_vm.referer.postalCode),callback:function ($$v) {_vm.$set(_vm.referer, "postalCode", $$v)},expression:"referer.postalCode"}}),_c('checkbox-input',{staticClass:"col-12",attrs:{"name":"PrivacyReferer","rules":{ required: { allowFalse: false } }},model:{value:(_vm.referer.privacyAgreement),callback:function ($$v) {_vm.$set(_vm.referer, "privacyAgreement", $$v)},expression:"referer.privacyAgreement"}},[_vm._t("privacy-referer")],2)],1)]):_vm._e(),_c('validation-observer',{ref:"athleteForm",staticClass:"col-12 col-md-6"},[_c('form',{staticClass:"row",attrs:{"id":"athlete-form"}},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"mb-2 mb-lg-4"},[_vm._v("Gegevens Beweger")])]),_c('text-input',{staticClass:"col-12 col-xl-6",attrs:{"name":"Voornaam","rules":"required"},model:{value:(_vm.athlete.firstName),callback:function ($$v) {_vm.$set(_vm.athlete, "firstName", $$v)},expression:"athlete.firstName"}}),_c('text-input',{staticClass:"col-12 col-xl-6",attrs:{"name":"Tussenvoegsel","rules":""},model:{value:(_vm.athlete.middleName),callback:function ($$v) {_vm.$set(_vm.athlete, "middleName", $$v)},expression:"athlete.middleName"}}),_c('text-input',{staticClass:"col-12 col-xl-6",attrs:{"name":"Achternaam","rules":"required"},model:{value:(_vm.athlete.name),callback:function ($$v) {_vm.$set(_vm.athlete, "name", $$v)},expression:"athlete.name"}}),_c('dropdown-input',{ref:"genderDropdown",staticClass:"col-6",attrs:{"options":_vm.genders,"name":"Gender","rules":"required"},model:{value:(_vm.athlete.gender),callback:function ($$v) {_vm.$set(_vm.athlete, "gender", $$v)},expression:"athlete.gender"}}),_c('text-input',{staticClass:"col-12 col-xl-6",attrs:{"name":"Geboortedatum (dd-mm-jjjj)","rules":{
                                required: true,
                                validDate: true
                            }},model:{value:(_vm.athlete.birthDate),callback:function ($$v) {_vm.$set(_vm.athlete, "birthDate", $$v)},expression:"athlete.birthDate"}}),_c('text-input',{staticClass:"col-12 col-xl-6",attrs:{"name":"Postcode (1234AB)","rules":{
                                required: true,
                                max: 11,
                                regex: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/
                            }},model:{value:(_vm.athlete.postalCode),callback:function ($$v) {_vm.$set(_vm.athlete, "postalCode", $$v)},expression:"athlete.postalCode"}}),_c('text-input',{staticClass:"col-6",attrs:{"name":"E-mailadres","rules":"required|email"},model:{value:(_vm.athlete.email),callback:function ($$v) {_vm.$set(_vm.athlete, "email", $$v)},expression:"athlete.email"}}),_c('text-input',{staticClass:"col-6",attrs:{"name":"Telefoonnummer","rules":{
                                required: true,
                                max: 11,
                                regex: /\d{3,4}[ -]{0,1}\d{6,7}/
                            }},model:{value:(_vm.athlete.telephoneNumber),callback:function ($$v) {_vm.$set(_vm.athlete, "telephoneNumber", $$v)},expression:"athlete.telephoneNumber"}}),(_vm.showRefererForm == false)?_c('dropdown-input',{ref:"welfareDropDown",staticClass:"col-12",attrs:{"options":_vm.welfareTypes,"name":"Heeft u het aanmelden met een zorg- of welzijnsprofessional besproken (bijvoorbeeld huisarts, fysiotherapeut, diëtist, welzijnswerker, maatschappelijk werker)?","rules":"required"},model:{value:(_vm.athlete.welfareType),callback:function ($$v) {_vm.$set(_vm.athlete, "welfareType", $$v)},expression:"athlete.welfareType"}}):_vm._e(),(
                                _vm.athlete.welfareType == '1' ||
                                    _vm.athlete.welfareType == '2'
                            )?_c('text-input',{staticClass:"col-6",attrs:{"name":"Naam professional:","rules":"required"},model:{value:(_vm.athlete.welfareProfName),callback:function ($$v) {_vm.$set(_vm.athlete, "welfareProfName", $$v)},expression:"athlete.welfareProfName"}}):_vm._e(),(
                                _vm.athlete.welfareType == '1' ||
                                    _vm.athlete.welfareType == '2'
                            )?_c('text-input',{staticClass:"col-6",attrs:{"name":"Naam organisatie:","rules":"required"},model:{value:(_vm.athlete.welfareOrgName),callback:function ($$v) {_vm.$set(_vm.athlete, "welfareOrgName", $$v)},expression:"athlete.welfareOrgName"}}):_vm._e(),_c('textarea-input',{staticClass:"col-12",attrs:{"name":"Opmerkingen"},model:{value:(_vm.athlete.comment),callback:function ($$v) {_vm.$set(_vm.athlete, "comment", $$v)},expression:"athlete.comment"}}),_c('checkbox-input',{staticClass:"col-12",attrs:{"name":"Contact"},model:{value:(_vm.athlete.contact),callback:function ($$v) {_vm.$set(_vm.athlete, "contact", $$v)},expression:"athlete.contact"}},[_vm._t("contact")],2),_c('checkbox-input',{staticClass:"col-12",attrs:{"name":"PrivacyAthlete","rules":{ required: { allowFalse: false } }},model:{value:(_vm.athlete.privacyAgreement),callback:function ($$v) {_vm.$set(_vm.athlete, "privacyAgreement", $$v)},expression:"athlete.privacyAgreement"}},[_vm._t("privacy-athlete")],2),_c('div',{staticClass:"col-8"},[_c('button',{staticClass:"btn btn-white",attrs:{"type":"submit","form":"athlete-form"},on:{"click":function($event){$event.preventDefault();return _vm.validateForms.apply(null, arguments)}}},[_vm._v(" Verstuur ")]),(_vm.validationError)?_c('p',{staticClass:"color--yellow-light"},[_vm._t("validation")],2):_vm._e()])],1)])],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showErrorsOrSuccess),expression:"showErrorsOrSuccess"}],staticClass:"bg--blue py-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-8"},[(_vm.success)?[_vm._t("success"),_c('button',{staticClass:"registration-form__button",on:{"click":_vm.resetForm}},[_c('i',{staticClass:"fas fa-sync-alt fa-lg mr-1"}),_vm._v(" Klik hier voor een nieuwe inschrijving ")])]:_vm._e(),(_vm.error)?[_vm._t("error")]:_vm._e()],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }