<template>
    <header class="header">
        <div class="header__background" :style="backgroundImage"></div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <slot></slot>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: ''
        },
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    computed: {
        backgroundImage() {
            const propValue = 'url("' + this.image + '")';
            return { 'background-image': propValue };
        }
    }
};
</script>

<style lang="scss">
.header {
    position: relative;
    width: 100%;
    padding: 8rem 0 5rem;
    background-size: cover;
    color: $white;
    background-image: linear-gradient(-44deg, #c0d730 0%, #00acd9 100%);

    @include media-breakpoint-up(md) {
        min-height: 420px;
    }
    &.small {
        @include media-breakpoint-up(md) {
            min-height: 250px;
        }
    }
}

.header__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    mix-blend-mode: overlay;
    filter: grayscale(1);
    background-size: cover;
}
</style>
