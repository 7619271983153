<template>
    <validation-provider
        v-slot="{ classes, errors }"
        class="dropdown-input"
        tag="div"
        :rules="rules"
        :name="name"
        :vid="vid"
    >
        <label class="input__label" :for="name">
            {{ name }} <span v-if="classes['required']">*</span>
        </label>
        <v-select
            :id="name"
            v-model="currentValue"
            class="dropdown-input__input"
            :class="classes"
            :clearable="false"
            :searchable="false"
            :options="options"
            :reduce="option => option.id"
            label="name"
            :multiple="false"
            @input="emitValue"
        ></v-select>
        <span class="input-error">{{ errors[0] }}</span>
    </validation-provider>
</template>

<script>
import vSelect from 'vue-select';
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationProvider,
        vSelect
    },
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        rules: {
            type: [String, Object],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        vid: {
            type: String,
            default: undefined
        },
        options: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            currentValue: null
        };
    },

    methods: {
        emitValue(value) {
            this.$emit('input', value);
        },

        resetValue() {
            this.currentValue = null;
        }
    }
};
</script>

<style lang="scss">
.dropdown-input {
    .v-select {
        font-size: 1.125rem;

        &.vs--open {
            opacity: 1;
        }
    }

    .vs__dropdown-toggle {
        border-radius: 5px;
        box-shadow: none;
        padding: 10px;
    }
    .vs__selected,
    .vs__search,
    .vs__search:focus {
        margin: 0;
        padding: 0;
        line-height: 1;
        border: none;
    }

    .vs__search {
        min-height: 21px;
    }
}
</style>
