<template>
    <section v-show="formIsOpen" ref="form" class="registration-form">
        <div class="bg--blue-dark py-4">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-6">
                        <slot name="intro"></slot>
                    </div>
                    <div class="col-1">
                        <button
                            class="registration-form__button"
                            @click="closeForm"
                        >
                            <i class="fas fa-lg fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!showErrorsOrSuccess" class="bg--blue py-4">
            <div class="container">
                <div class="row ">
                    <validation-observer
                        v-if="showRefererForm"
                        ref="refererForm"
                        class="col-12 col-md-6"
                        tag="div"
                    >
                        <form id="referer-form" class="row mb-3 mb-lg-0">
                            <div class="col-12">
                                <h3 class="mb-2 mb-lg-4">Gegevens Verwijzer</h3>
                            </div>
                            <text-input
                                v-model="referer.firstName"
                                class="col-6"
                                name="Voornaam"
                                rules="required"
                            />
                            <text-input
                                v-model="referer.lastName"
                                class="col-6"
                                name="Achternaam"
                                rules="required"
                            />
                            <text-input
                                v-model="referer.email"
                                class="col-6"
                                name="E-mailadres"
                                rules="required|email"
                            />
                            <text-input
                                v-model="referer.telephoneNumber"
                                class="col-6"
                                name="Telefoonnummer"
                                :rules="{
                                    max: 11,
                                    regex: /\d{3,4}[ -]{0,1}\d{6,7}/
                                }"
                            />

                            <dropdown-input
                                v-model="referer.referrerType"
                                class="col-6"
                                :options="refererTypes"
                                name="Type verwijzer"
                                rules="required"
                            />

                            <text-input
                                v-model="referer.practice"
                                class="col-6"
                                name="Praktijk/Locatie"
                                rules="required"
                            />

                            <text-input
                                v-model="referer.postalCode"
                                class="col-12 col-xl-6"
                                name="Postcode (1234AB)"
                                :rules="{
                                    required: true,
                                    max: 11,
                                    regex: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/
                                }"
                            />

                            <checkbox-input
                                v-model="referer.privacyAgreement"
                                class="col-12"
                                name="PrivacyReferer"
                                :rules="{ required: { allowFalse: false } }"
                            >
                                <slot name="privacy-referer"></slot>
                            </checkbox-input>
                        </form>
                    </validation-observer>

                    <validation-observer
                        ref="athleteForm"
                        class="col-12 col-md-6"
                    >
                        <form id="athlete-form" class="row">
                            <div class="col-12">
                                <h3 class="mb-2 mb-lg-4">Gegevens Beweger</h3>
                            </div>
                            <text-input
                                v-model="athlete.firstName"
                                class="col-12 col-xl-6"
                                name="Voornaam"
                                rules="required"
                            />

                            <text-input
                                v-model="athlete.middleName"
                                class="col-12 col-xl-6"
                                name="Tussenvoegsel"
                                rules=""
                            />

                            <text-input
                                v-model="athlete.name"
                                class="col-12 col-xl-6"
                                name="Achternaam"
                                rules="required"
                            />

                            <dropdown-input
                                ref="genderDropdown"
                                v-model="athlete.gender"
                                class="col-6"
                                :options="genders"
                                name="Gender"
                                rules="required"
                            />

                            <text-input
                                v-model="athlete.birthDate"
                                class="col-12 col-xl-6"
                                name="Geboortedatum (dd-mm-jjjj)"
                                :rules="{
                                    required: true,
                                    validDate: true
                                }"
                            />

                            <text-input
                                v-model="athlete.postalCode"
                                class="col-12 col-xl-6"
                                name="Postcode (1234AB)"
                                :rules="{
                                    required: true,
                                    max: 11,
                                    regex: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/
                                }"
                            />
                            <text-input
                                v-model="athlete.email"
                                class="col-6"
                                name="E-mailadres"
                                rules="required|email"
                            />
                            <text-input
                                v-model="athlete.telephoneNumber"
                                class="col-6"
                                name="Telefoonnummer"
                                :rules="{
                                    required: true,
                                    max: 11,
                                    regex: /\d{3,4}[ -]{0,1}\d{6,7}/
                                }"
                            />

                            <dropdown-input
                                v-if="showRefererForm == false"
                                ref="welfareDropDown"
                                v-model="athlete.welfareType"
                                class="col-12"
                                :options="welfareTypes"
                                name="Heeft u het aanmelden met een zorg- of welzijnsprofessional besproken (bijvoorbeeld huisarts, fysiotherapeut, diëtist, welzijnswerker, maatschappelijk werker)?"
                                rules="required"
                            />

                            <text-input
                                v-if="
                                    athlete.welfareType == '1' ||
                                        athlete.welfareType == '2'
                                "
                                v-model="athlete.welfareProfName"
                                class="col-6"
                                name="Naam professional:"
                                rules="required"
                            />

                            <text-input
                                v-if="
                                    athlete.welfareType == '1' ||
                                        athlete.welfareType == '2'
                                "
                                v-model="athlete.welfareOrgName"
                                class="col-6"
                                name="Naam organisatie:"
                                rules="required"
                            />

                            <textarea-input
                                v-model="athlete.comment"
                                class="col-12"
                                name="Opmerkingen"
                            />

                            <checkbox-input
                                v-model="athlete.contact"
                                class="col-12"
                                name="Contact"
                            >
                                <slot name="contact"></slot>
                            </checkbox-input>

                            <checkbox-input
                                v-model="athlete.privacyAgreement"
                                class="col-12"
                                name="PrivacyAthlete"
                                :rules="{ required: { allowFalse: false } }"
                            >
                                <slot name="privacy-athlete"></slot>
                            </checkbox-input>

                            <div class="col-8">
                                <button
                                    class="btn btn-white"
                                    type="submit"
                                    form="athlete-form"
                                    @click.prevent="validateForms"
                                >
                                    Verstuur
                                </button>

                                <p
                                    v-if="validationError"
                                    class="color--yellow-light"
                                >
                                    <slot name="validation"></slot>
                                </p>
                            </div>
                        </form>
                    </validation-observer>
                </div>
            </div>
        </div>

        <div v-show="showErrorsOrSuccess" class="bg--blue py-4">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <template v-if="success">
                            <slot name="success"></slot>
                            <button
                                class="registration-form__button"
                                @click="resetForm"
                            >
                                <i class="fas fa-sync-alt fa-lg mr-1"></i> Klik
                                hier voor een nieuwe inschrijving
                            </button>
                        </template>
                        <template v-if="error">
                            <slot name="error"></slot>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { ValidationObserver, extend } from 'vee-validate';
import { initVeeValidate } from '@/plugins/vee-validate';
import checkboxInput from '@/components/form/checkbox-input';
import dropdownInput from '@/components/form/dropdown-input';
import textInput from '@/components/form/text-input';
import textareaInput from '@/components/form/textarea-input';

initVeeValidate();

export default {
    components: {
        checkboxInput,
        dropdownInput,
        textInput,
        textareaInput,
        ValidationObserver
    },

    props: {
        supplierEmail: {
            type: String,
            default: ''
        },
        activityName: {
            type: String,
            default: ''
        },
        area: {
            type: String,
            default: ''
        },
        activity: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            referer: {
                firstName: '',
                lastName: '',
                email: '',
                telephoneNumber: '',
                referrerType: '',
                practice: '',
                postalCode: '',
                privacyAgreement: false
            },
            athlete: {
                firstName: '',
                middleName: '',
                name: '',
                gender: '',
                email: '',
                telephoneNumber: '',
                comment: '',
                contact: false,
                privacyAgreement: false,
                welfareType: '',
                healthcareProvider: '',
                welfareProfName: '',
                welfareOrgName: '',
                birthDate: '',
                postalCode: ''
            },
            error: false,
            validationError: false,
            success: false,
            formIsOpen: false,
            showRefererForm: false,
            welfareTypes: [],
            refererTypes: [],
            genders: []
        };
    },

    computed: {
        showErrorsOrSuccess() {
            return this.success || this.error;
        }
    },

    created() {
        this.getRefererTypes();
        this.getGenders();
        this.getWelfareTypes();
        this.getReferrerData();
    },

    mounted() {
        this.$root.$on('open-form', data => {
            this.formIsOpen = true;
            this.showRefererForm = data.openRefererForm;
            this.$nextTick(() => {
                this.$refs.form.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            });
        });

        extend('validDate', {
            validate: value => {
                return this.convertDateStringToISODate(value)
                    ? true
                    : '{_field_} formaat is ongeldig';
            }
        });
    },
    methods: {
        getReferrerData() {
            if (JSON.parse(localStorage.getItem('referrer-form')) != null) {
                this.referer = JSON.parse(
                    localStorage.getItem('referrer-form')
                );
            }
        },
        validateForms() {
            if (this.showRefererForm) {
                const validateReferer = this.$refs.refererForm.validate();
                const validateAthlete = this.$refs.athleteForm.validate();

                Promise.all([validateReferer, validateAthlete]).then(values => {
                    if (values[0] && values[1]) {
                        Promise.all([
                            this.postRefererForm(),
                            this.postAthleteForm()
                        ])
                            .then(() => {
                                this.success = true;
                                localStorage.setItem(
                                    'referrer-form',
                                    JSON.stringify(this.referer)
                                );
                            })
                            .catch(error => {
                                if (error.response.status === 400) {
                                    this.validationError = true;
                                } else {
                                    this.error = true;
                                }
                            });
                    }
                });
            } else {
                this.$refs.athleteForm.handleSubmit(() => {
                    this.postAthleteForm()
                        .then(() => {
                            this.success = true;
                        })
                        .catch(error => {
                            if (error.response.status === 400) {
                                this.validationError = true;
                            } else {
                                this.error = true;
                            }
                        });
                });
            }
        },

        closeForm() {
            this.formIsOpen = false;
        },

        resetForm() {
            this.$refs.athleteForm.reset();
            this.athlete = {};
            this.success = false;
            this.$refs.genderDropdown.resetValue();
            this.$refs.welfareDropDown.resetValue();
        },

        getRefererTypes() {
            axios.get('/api/registration/getreferrertypes').then(response => {
                this.refererTypes = response.data;
            });
        },
        getGenders() {
            axios.get('/api/registration/getgendertypes').then(response => {
                this.genders = response.data;
            });
        },
        getWelfareTypes() {
            axios.get('/api/registration/getwelfaretypes').then(response => {
                this.welfareTypes = response.data;
            });
        },
        postRefererForm() {
            return axios({
                method: 'post',
                url: '/api/registration/addreferrer',
                data: this.referer
            });
        },
        convertDateStringToISODate(dateString) {
            const dateArray = dateString.split('-').reverse();
            dateArray[1]--; //month is zero-indexed

            const date = new Date(Date.UTC(...dateArray));

            return !isNaN(date.valueOf()) &&
                date.getFullYear() == dateArray[0] &&
                date.getMonth() == dateArray[1] &&
                date.getDate() == dateArray[2]
                ? date
                : null;
        },
        postAthleteForm() {
            if (this.showRefererForm == false) {
                this.referer = {};
            }

            return axios({
                method: 'post',
                url: '/api/registration/addathlete',
                data: {
                    firstName: this.athlete.firstName,
                    middleName: this.athlete.middleName,
                    name: this.athlete.name,
                    gender: this.athlete.gender,
                    email: this.athlete.email,
                    telephoneNumber: this.athlete.telephoneNumber,
                    comment: this.athlete.comment,
                    SpokenToWelfareProfessional: this.athlete.welfareType,
                    healthcareProvider: this.athlete.healthcareProvider,
                    welfareProfessionalName: this.athlete.welfareProfName,
                    welfareOrganisationName: this.athlete.welfareOrgName,
                    area: this.area,
                    activity: this.activity,
                    contactBeweegplus: this.athlete.contact,
                    referrerEmail: this.referer.email,
                    isRefererForm: this.showRefererForm,
                    supplierEmail: this.supplierEmail,
                    activityName: this.activityName,
                    postalCode: this.athlete.postalCode,
                    registrationDate: new Date(),
                    birthDate: this.convertDateStringToISODate(
                        this.athlete.birthDate
                    )
                }
            });
        }
    }
};
</script>

<style lang="scss">
.registration-form {
    color: $white;
}

.registration-form__button {
    color: $white;
    background-color: transparent;
    border: none;
}
</style>
