<template>
    <div>
        <div class="card">
            <img class="card__image" :src="activity.image" alt="placeholder" />
            <img
                v-if="activity.beweegPlus"
                class="beweeg-plus"
                src="/content/img/beweegplus.png"
            />
            <div class="card__content">
                <h3 class="color--blue h2">{{ activity.activityName }}</h3>
                <div class="font-weight-bold">{{ activity.supplierName }}</div>
                <div
                    v-for="date in activity.openDate"
                    :key="date"
                    class="small font-weight-light"
                >
                    {{ date }}
                </div>
                <div class="small font-weight-light font-italic">
                    {{ activity.area }}
                </div>
            </div>
            <div class="card__footer">
                <a :href="activity.url" class="card__button stretched-link">
                    <i class="fas fa-chevron-right"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activity: {
            type: Object,
            default() {
                return {};
            }
        }
    }
};
</script>

<style lang="scss">
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    border-radius: 2px;
    border-top-right-radius: 22px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

    &:hover {
        background-image: linear-gradient(
            to right,
            rgba($blue-light, 0.1),
            rgba($blue-light, 0.05)
        );
        .color--blue {
            color: #2a8c90;
        }
    }
}

.card__image {
    width: 100%;
    height: auto;
    min-width: 100%;
    min-height: 225px;
    background-color: $gray;
}

.beweeg-plus {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 1;
    left: 0;
    top: 0;
}

.card__content {
    flex: 1 1 auto;
    padding: 20px 20px 20px;
    font-size: 1.125rem;
    line-height: 1.78;
    margin-bottom: -50px;
}

.card__footer {
    width: 100%;
    height: auto;
}

.card__button {
    display: block;
    float: right;
    border-top-left-radius: 3rem;
    background-color: rgba($blue, 0.3);
    line-height: 1;
    padding: 21px 12px 9px 26px;

    i {
        font-size: 0.875rem;
        color: $black;
    }
}
</style>
