<template>
    <validation-provider
        v-slot="{ classes, errors }"
        class="textarea-input"
        tag="div"
        :rules="rules"
        :name="name"
        :vid="vid"
    >
        <label class="input__label" :for="name">
            {{ name }} <span v-if="classes['required']">*</span>
        </label>
        <textarea
            :id="name"
            v-model="value"
            class="textarea-input__input"
            :class="classes"
            :rows="rows"
            @input="emitValue"
        />
        <span class="input-error">{{ errors[0] }}</span>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationProvider
    },

    props: {
        value: {
            type: String,
            default: ''
        },
        rules: {
            type: [String, Object],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        vid: {
            type: String,
            default: undefined
        },
        rows: {
            type: Number,
            default: 5
        }
    },

    methods: {
        emitValue(event) {
            this.$emit('input', event.target.value);
        }
    }
};
</script>

<style lang="scss">
.textarea-input__input {
    width: 100%;
    font-size: 1.125rem;
    border-radius: 5px;
    padding: 10px;
    line-height: 1;
    border: none;

    &:focus {
        outline: none;
        opacity: 1;
    }
}
</style>
