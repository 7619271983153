import Vue from 'vue';
import WebFontLoader from 'webfontloader';

import activityCta from '@/components/activities/activity-cta';
import activityOverview from '@/components/activities/activity-overview';
import blockHeader from '@/components/blocks/block-header';
import blockRelated from '@/components/blocks/block-related';
import blockSlider from '@/components/blocks/block-slider';
import blockText from '@/components/blocks/block-text';
import logo from '@/components/logo';
import registrationForm from '@/components/form/registration-form';

import '@/scss/main.scss';

import sentry from '@/plugins/sentry';
sentry.init(window.settings.environment, window.settings.sentryDSN);

Vue.config.productionTip = false;

window.app = new Vue({
    el: '#app',

    components: {
        activityCta,
        activityOverview,
        blockHeader,
        blockRelated,
        blockSlider,
        blockText,
        logo,
        registrationForm
    },

    created() {
        WebFontLoader.load({
            typekit: {
                id: 'wue0tyi'
            },
            google: {
                families: ['Work Sans:300&display=swap']
            }
        });
    },

    mounted() {
        const state = setInterval(() => {
            if (
                document.readyState === 'interactive' ||
                document.readyState === 'complete'
            ) {
                this.$el.classList.remove('preloader--loading');
                clearInterval(state);
            }
        }, 100);
    }
});
