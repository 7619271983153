<template>
    <section class="block-related py-2">
        <div class="container">
            <div class="row block-related__cards">
                <activity-card
                    v-for="activity in activities"
                    :key="activity.id"
                    class="col-12 col-md-6 col-lg-4 mb-4"
                    :activity="activity"
                ></activity-card>
            </div>
        </div>
    </section>
</template>

<script>
import activityCard from '@/components/activities/activity-card';

export default {
    components: {
        activityCard
    },

    props: {
        activities: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>
