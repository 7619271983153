<template>
    <section class="block-slider py-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="slider__wrapper">
                        <div v-for="image in images" :key="image">
                            <img
                                class="slider__image"
                                :src="image.src"
                                :alt="image.alt"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider.js';

export default {
    props: {
        images: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            slider: null
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.initSlider();
        });
    },

    methods: {
        initSlider() {
            this.slider = tns({
                container: this.$el.querySelector('.slider__wrapper'),
                gutter: 30,
                items: 1,
                slideBy: 1,
                controlsText: [
                    '<i class="fas fa-chevron-left"></i>',
                    '<i class="fas fa-chevron-right"></i>'
                ],
                autoplay: true,
                controls: false,
                nav: true,
                lazyload: false,
                mouseDrag: true,
                rewind: true,
                responsive: {
                    768: {
                        items: 3,
                        controls: true,
                        nav: false
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss">
@import '~tiny-slider/src/tiny-slider.scss';

.tns-outer {
    position: relative;
}

.tns-controls {
    position: absolute;
    display: flex;
    height: 100%;
    left: -75px;
    right: -75px;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    button {
        pointer-events: all;
        border: none;
        background-color: transparent;
        color: $blue;

        i {
            font-size: 1.5rem;
        }

        &:hover {
            cursor: pointer;
            color: $blue-dark;
        }

        &:focus {
            outline: none;
        }
    }
}

.tns-outer [data-action] {
    display: none;
}

.tns-nav {
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);

    button {
        width: 1rem;
        height: 1rem;
        margin: 0.5rem;
        border: solid 2px $blue;
        border-radius: 0.5rem;
        background-color: transparent;

        &:focus {
            outline: none;
        }
    }

    .tns-nav-active {
        background-color: $blue;
    }
}

.slider__image {
    width: 100%;
    height: auto;
    border-radius: 3px;
}
</style>
