<template>
    <validation-provider
        v-slot="{ classes, errors }"
        class="text-input"
        tag="div"
        :rules="rules"
        :name="name"
        :vid="vid"
    >
        <label class="input__label" :for="name"
            >{{ name }} <span v-if="classes['required']">*</span>
        </label>
        <input
            :id="name"
            v-model="currentValue"
            class="text-input__input"
            :type="type"
            :class="classes"
            :autocomplete="name"
            :name="name"
            v-bind="$attrs"
        />
        <span class="input-error">{{ errors[0] }}</span>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationProvider
    },

    props: {
        value: {
            type: String,
            default: ''
        },
        rules: {
            type: [String, Object],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        vid: {
            type: String,
            default: undefined
        },
        type: {
            type: String,
            default: 'text'
        }
    },
    computed: {
        currentValue: {
            get() {
                return this.value;
            },
            set(currentValue) {
                this.$emit('input', currentValue);
            }
        }
    },
    methods: {
        emitValue(event) {
            this.$emit('input', event.target.value);
        }
    }
};
</script>

<style lang="scss">
.text-input__input {
    width: 100%;
    font-size: 1.125rem;
    border-radius: 5px;
    padding: 10px;
    line-height: 1;
    border: none;

    &:focus {
        outline: none;
        opacity: 1;
    }
}
</style>
