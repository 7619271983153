<template>
    <validation-provider
        v-slot="{ classes, errors }"
        class="checkbox-input"
        tag="div"
        :rules="rules"
        :name="name"
        :vid="vid"
    >
        <input
            :id="name"
            v-model="value"
            class="checkbox-input__input"
            type="checkbox"
            :value="value"
            :class="classes"
            @input="emitValue"
        />
        <label class="checkbox-input__label mb-3 mb-lg-0" :for="name">
            <slot></slot> <span v-if="classes['required']" class="ml-1">*</span>
        </label>
        <span class="input-error">{{ errors[0] }}</span>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationProvider
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        rules: {
            type: [String, Object],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        vid: {
            type: String,
            default: undefined
        }
    },

    methods: {
        emitValue() {
            this.$emit('input', !this.value);
        }
    }
};
</script>

<style lang="scss">
.checkbox-input__input {
    display: none;
}

.checkbox-input__label {
    position: relative;
    display: flex;
    user-select: none;
    padding-left: 56px;
    margin-bottom: 0;

    a {
        color: $white;
        text-decoration: underline;
    }

    p {
        margin: 0;
    }

    &:hover {
        cursor: pointer;

        &:before {
            opacity: 1;
        }
    }
}

.checkbox-input__label:before,
.checkbox-input__label:after {
    position: absolute;
    display: block;
}

.checkbox-input__label:before {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: $white;
    left: 0;
    top: -5px;
    opacity: 0.7;

    .checkbox-input__input:checked + & {
        opacity: 1;
    }
}

.checkbox-input__label:after {
    top: 15px;
    left: 20px;
    transform: translate(-50%, -50%);
    color: $blue;
    line-height: 1;
    font-size: 1.125rem;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;

    .checkbox-input__input:checked + & {
        content: '';
    }
}
</style>
