<template>
    <div>
        <section class="homepage-header">
            <div
                class="homepage-header__image d-md-none"
                :style="{ backgroundImage: 'url(' + image + ')' }"
            ></div>
            <div class="homepage-header__slider d-none d-md-block">
                <div v-for="sliderImage in sliderImages" :key="sliderImage">
                    <div
                        class="slider-image"
                        :style="{
                            backgroundImage: 'url(' + sliderImage.url + ')'
                        }"
                    ></div>
                </div>
            </div>
        </section>
        <block-header>
            <slot name="header"></slot>
            <!-- display: block -->
            <v-select
                v-model="filters.area"
                :searchable="false"
                :options="options.area.filters"
                label="name"
                :reduce="option => option.filter"
                :multiple="false"
                :placeholder="'In welk gebied wil je gaan sporten?'"
            ></v-select>

            <div class="row d-none">
                <text-input
                    v-model="filters.radius"
                    class="col-12 col-md-6"
                    type="number"
                    step="0.1"
                    min="0.1"
                    name="Straal in KM"
                    :disabled="loading"
                ></text-input>
                <text-input
                    v-model="filters.postalCode"
                    class="col-12 col-md-6"
                    type="text"
                    name="Postcode"
                    :disabled="loading"
                ></text-input>
            </div>
        </block-header>

        <main>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3 activity-overview__filters">
                        <checkbox-group
                            v-if="options.beweegPlus.isActive"
                            v-model="filters.beweegPlus"
                            :options="options.beweegPlus.filters"
                        >
                            <h2 class="h3">Beweegplus</h2>
                        </checkbox-group>
                        <checkbox-group
                            v-model="filters.ageGroups"
                            :options="options.ageGroups.filters"
                        >
                            <h2 class="h3">Leeftijdsgroepen</h2>
                        </checkbox-group>
                        <checkbox-group
                            v-model="filters.suitableFor"
                            :options="options.suitableFor.filters"
                        >
                            <h2 class="h3">Specifiek geschikt voor</h2>
                        </checkbox-group>
                    </div>

                    <div class="col-12 col-lg-9 activity-overview__items">
                        <div class="row">
                            <activity-card
                                v-for="activity in activities"
                                :key="activity.id"
                                class="col-12 col-md-6 col-lg-4 mb-4"
                                :activity="activity"
                            ></activity-card>
                        </div>

                        <div class="w-100 d-flex justify-content-center">
                            <spinner v-if="loading" />

                            <slot
                                v-if="!loading && activities.length === 0"
                                name="no-results"
                            ></slot>

                            <p v-if="error">
                                Er geen iets mis, probeer het later opnieuw.
                            </p>

                            <p v-if="zipcodeValidation">
                                Opgegeven postcode is niet valide
                            </p>

                            <button
                                v-if="!loading && currentPage < pageCount - 1"
                                class="btn btn-secondary w-auto"
                                @click="getNextPage"
                            >
                                Meer resultaten laden
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import axios from 'axios';
import blockHeader from '@/components/blocks/block-header';
import activityCard from '@/components/activities/activity-card';
import checkboxGroup from '@/components/form/checkbox-group';
import spinner from '@/components/spinner';
import vSelect from 'vue-select';
import TextInput from '../form/text-input.vue';
import { tns } from 'tiny-slider/src/tiny-slider.js';

export default {
    components: {
        activityCard,
        blockHeader,
        checkboxGroup,
        spinner,
        vSelect,
        TextInput
    },

    props: {
        image: {
            type: String,
            default: ''
        },
        sliderImages: {
            type: Array
        }
    },

    data() {
        return {
            options: {
                area: {},
                suitableFor: {},
                ageGroups: {},
                beweegPlus: {}
            },
            filters: {
                area: '',
                suitableFor: [],
                ageGroups: [],
                beweegPlus: [],
                radius: '',
                postalCode: ''
            },
            activities: [],
            pageCount: 0,
            currentPage: 0,
            zipcodeValidation: false,
            error: false,
            loading: false,
            gettingFilters: false,
            filterDelay: null,
            slider: null
        };
    },

    computed: {
        joinedFilters() {
            const filterList = [...this.filters.suitableFor];
            if (this.filters.ageGroups) {
                for (let i = 0; i < this.filters.ageGroups.length; i++) {
                    filterList.push(this.filters.ageGroups[i]);
                }
            }
            if (this.filters.beweegPlus) {
                for (let i = 0; i < this.filters.beweegPlus.length; i++) {
                    filterList.push(this.filters.beweegPlus[i]);
                }
            }
            if (this.filters.area) {
                filterList.push(this.filters.area);
            }

            return filterList.join(',');
        }
    },

    watch: {
        filters: {
            handler() {
                clearTimeout(this.filterDelay);
                this.filterDelay = setTimeout(() => {
                    const regex = /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i;
                    this.zipcodeValidation = false;

                    if (this.filters.postalCode.length) {
                        if (!regex.test(this.filters.postalCode)) {
                            this.zipcodeValidation = true;
                            return;
                        }
                    }

                    this.currentPage = 0;
                    this.activities = [];
                    sessionStorage.setItem(
                        'activity-overview',
                        JSON.stringify(this.filters)
                    );

                    this.getActivities();
                }, 500);
            },
            deep: true
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.initSlider();
        });
    },

    created() {
        document.addEventListener('scroll', this.onScrollActivitiesLoader);

        this.getSavedFilters();
        if (this.gettingFilters == false) {
            this.getActivities();
        }
    },

    destroyed() {
        document.removeEventListener('scroll', this.onScrollActivitiesLoader);
    },

    methods: {
        onScrollActivitiesLoader() {
            const footer = document.querySelector('footer');
            const offsetHeight =
                document.body.offsetHeight - footer.offsetHeight;
            if (window.innerHeight + window.scrollY >= offsetHeight) {
                this.getNextPage();
            }
        },
        getSavedFilters() {
            const getActivityOverviewStorage = JSON.parse(
                sessionStorage.getItem('activity-overview')
            );

            if (getActivityOverviewStorage != null) {
                this.gettingFilters = true;
                this.filters = getActivityOverviewStorage;
            }
        },
        getActivities() {
            this.loading = true;
            this.error = false;

            axios
                .get('/api/activity/filteredactivities', {
                    params: {
                        filters: this.joinedFilters,
                        radius: parseInt(this.filters.radius),
                        postalCode: this.filters.postalCode,
                        page: this.currentPage
                    }
                })
                .then(response => {
                    this.options = response.data.filters;
                    this.pageCount = response.data.pageCount;
                    this.activities = this.activities.concat(
                        response.data.results
                    );
                })
                .catch(() => {
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getNextPage() {
            if (this.currentPage < this.pageCount) {
                this.currentPage += 1;
                this.getActivities();
            }
        },

        initSlider() {
            this.slider = tns({
                disable: true,
                container: this.$el.querySelector('.homepage-header__slider'),
                gutter: 30,
                items: 1,
                slideBy: 1,
                controls: false,
                nav: true,
                lazyload: false,
                autoplay: true,
                autoplayTimeout: 8000,
                responsive: {
                    768: {
                        disable: false
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss">
.activity-overview__filters,
.activity-overview__items {
    padding: 4rem 0;
}

.activity-overview__filters {
    color: $black;
    background-color: $cool-white;
    font-weight: 400;
    font-size: $font-size-sm;
}

.homepage-header__image {
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.homepage-header__slider {
    height: auto;
}

.homepage-header {
    .tns-nav {
        bottom: 1rem;
        z-index: 2;
    }
    .tns-nav button.tns-nav-active {
        background-color: $white;
        border-color: $white;
    }
    .tns-nav button {
        border: solid 2px #d5d5d5;
        background-color: #d5d5d5;
    }
    .slider-image {
        width: 100%;
        height: 500px;
        border-radius: 3px;
        background-size: cover;
        background-position: center;
    }
    + .header {
        padding: 2rem 0 3rem 0;
        min-height: 0;
    }
}
</style>
