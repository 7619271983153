import { render, staticRenderFns } from "./logo.vue?vue&type=template&id=235e3af5&scoped=true&functional=true&"
var script = {}
import style0 from "./logo.vue?vue&type=style&index=0&id=235e3af5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "235e3af5",
  null
  
)

export default component.exports